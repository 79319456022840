

import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function LearnicationPage({ location }) {
  return (
    <Layout location={location}>
      <Seo title="Learnication" />
      <section className="mt-8 lg:mt-18 mb-20">
        <header>
          <h1>Learnication</h1>
        </header>
        <article className="pt-16 pb-64 prose lg:prose-xl">
          <p>I am from Riga, Latvia. It's a picturesque Hanseatic city on the shores of the Baltic Sea. We have a great opportunity to invite your team for LearniCation to Riga for 3-4 days for a long weekend.</p>
          <p>LearniCation experience includes:</p>
          <ul className="pl-10 my-4 list-disc">
            <li>Deep dive into a technical Topic of your choice with an experienced trainer</li>
            <li>Team building activities (Quizzes, games, quests)</li>
            <li>Accommodation (4-star hotel in the centre of Riga with great SPA zone)</li>
            <li>Dining in the best restaurants</li>
            <li>Inspiring walk in Old Riga with a local guide</li>
            <li>Transfer to/from airport</li>
          </ul>
          <p>It is an opportunity for your employees to socialize, learn something new, travel and relax.</p>
          <p>We will take care of everything, you need just pick up a topic and a date. The rest is on us.</p>
          <p>You will have great time with us!</p>
        </article>
      </section>
    </Layout>
  )
}

export default LearnicationPage
